.tableOrder {
  width: 100%;
  // background: white;
  direction: ltr;
  // border: 1px solid #d2d2d2;
  // thead {
  //   tr {
  //     &:hover {
  //       background: linear-gradient(to top, #2686f9, #4b9cfd) !important;
  //     }
  //   }
  // }
  tr {
    cursor: pointer;
    display: flex;
    border-bottom: 1px solid #d2d2d2;
    // &:hover {
    //   background: ghostwhite;
    // }
  }
  td,
  th {
    padding: 10px;
    justify-content: flex-start;
    display: flex;
    width: 100%;
    min-width: 100px;
    padding-top: 15px;
    padding-bottom: 15px;
    // justify-content: center;
  }
  thead {
    td,
    th {
      font-size: 14px;
      color: #2575fe;
      font-weight: 900;
    }
    // background: linear-gradient(to top, #2686f9, #4b9cfd);
    // color: white;
  }
}
.label {
  justify-content: center;
  display: flex;
  font-size: 14px;
  padding: 8px;
  color: #fff;
  background: #4497fc;
}
.tableScroll {
  overflow: hidden;
  @media (max-width: 900px) {
    overflow-x: auto;
  }
}
.region {
  background: #f2f3f4;;
  border-radius: 16px;
  padding: 2px 10px;
}
.valueText {
  width: 120px;
}
.valueTextSm {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}
.value {
  padding: 8px;
  border-bottom: 1px solid #ececec;
  display: flex;
  cursor: pointer;
  color: #383838;
  &:hover {
    background: ghostwhite;
  }
}
.disableRow {
  background-color: #e0e0e0;
  pointer-events: none;
  cursor: unset;
  opacity: 0.46;
  &:hover {
    background-color: #e0e0e0 !important;
  }
}
// .active {
//   background: #e4eaf5;
//   &:hover {
//     background: #e4eaf5 !important;
//     pointer-events: none !important;
//   }
// }
