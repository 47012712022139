@import '../../styles/global';

.sidebar {
  @include transition(all 0.4s);

  &.open {
    .overlay {
      display: block;
    }

    .menuWrapper {
      right: 0;
    }
  }

  .menuWrapper {
    @include transition(right 0.35s ease-out);
    overflow: hidden;
    .sidebar-menu {
      .iconLink {
        img {
          width: 18px;
          height: 18px;
          margin-left: 12px;
        }
        span{
          margin-right: 5px;
        }
      }

      .actionNav {
        .iconLink {
          span {
            font-family: $medium-font;
          }
        }
      }

      .downloadBox {
        border: solid 0.5px #dddddd;
      }
    }
  }
}
.fileUpload {
  font-size: 30px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
body:lang(en) {
  .sidebar {
    &.open {
      .menuWrapper {
        left: 0;
      }
    }

    .menuWrapper {
      right: auto;
      left: 0;
      @include transition(left 0.35s ease-out);

      @media (max-width: $tablet) {
        left: -($sidebar-width);
      }

      .sidebar-menu {
        .upFromUrl {
          padding-right: 0;
          padding-left: 11px;
          text-align: left;
        }

        .iconLink {
          img {
            margin-right: 12px;
            margin-left: 0;
          }
        }

        .actionNav {
          .iconLink {
            margin-right: 0;
            margin-left: 11px;
          }
        }
      }
    }
  }
}
.sidebar-menu {
  position: absolute;
  top: 2.5rem;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 1.875rem;
  padding-left: 1.875rem;
  height: calc(100vh - 40px);
}
@media (max-height: 800px) {
  .sidebar-menu {
    overflow-y: auto;
    height: auto;
  }
}
.overflow-y {
  height: auto;
}
