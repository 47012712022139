@import '../../styles/global.scss';
.loadingLine {
  height: 10px;
  border-radius: 5px;
  width: 100px;
  margin: 10px;
  border: 1px solid lightgrey;
  -webkit-animation: pulse 1s infinite ease-in-out;
  animation: pulse 1s infinite ease-in-out;
}
.loadingVLineSm {
  height: 10px;
  border-radius: 5px;
  width: 25px;
  margin: 10px;
  border: 1px solid lightgrey;
  -webkit-animation: pulse 1s infinite ease-in-out;
  animation: pulse 1s infinite ease-in-out;
}
.loadingLineM {
  height: 10px;
  border-radius: 5px;
  width: 100px;
  margin: 0 10px 10px;
  border: 1px solid lightgrey;
  -webkit-animation: pulse 1s infinite ease-in-out;
  animation: pulse 1s infinite ease-in-out;
}
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}
.loadingLineSm {
  height: 10px;
  border-radius: 5px;
  width: 50px;
  margin: 10px;
  border: 1px solid lightgrey;
  -webkit-animation: pulse 1s infinite ease-in-out;
  animation: pulse 1s infinite ease-in-out;
}
.loadingPic {
  height: 28px;
  border-radius: 5px;
  width: 28px;
  margin: 10px;
  border: 1px solid lightgrey;
  -webkit-animation: pulse 1s infinite ease-in-out;
  animation: pulse 1s infinite ease-in-out;
}
.loadingBigPic {
 
  border-radius: 5px;
  width: 60px;
  height: 60px;
  margin: 10px 10px 2px ;
  border: 1px solid lightgrey;
  -webkit-animation: pulse 1s infinite ease-in-out;
  animation: pulse 1s infinite ease-in-out;
}
.fullCard{
  width:100% !important;
}
.card {
  width: 230px;
  height: 175px;
  border: solid 1px #e5e5e5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  margin-top: 45px;
  margin-left: 45px;
  padding: 13px 10px 10px;
  background-color: #fff;
  border-radius: 3px;
  @media (min-width: $web-medium + 1) and (max-width: $web-medium-max) {
    margin-bottom: 3px;
    margin-left: 3px;
    flex-basis: calc(33.33% - 3px);
  }

  @media (min-width: $tablet + 1) and (max-width: $web-medium) {
    margin-bottom: 4px;
    margin-left: 4px;
    flex-basis: calc(50% - 4px);
  }

  @media (min-width: $tablet - 63) and (max-width: $tablet) {
    margin-bottom: 5px;
    margin-left: 5px;
    flex-basis: calc(33.33% - 5px);
  }

  @media (min-width: $tablet - 278) and (max-width: $tablet - 62) {
    margin-bottom: 7px;
    margin-left: 7px;
    flex-basis: calc(50% - 7px);
  }
  @media (max-width: $tablet - 277) {
    flex-basis: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
}
.btn {
  height: 35px;
  border-radius: 5px;
  margin-top: 15px;
  border: 1px solid lightgrey;
  -webkit-animation: pulse 1s infinite ease-in-out;
  animation: pulse 1s infinite ease-in-out;
}
@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
