@import '../../../../styles/global';

.upgradeAccount {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  margin-top: 58px;
  margin-bottom: 30px;
  height: 126px;
  border: 1px solid #fae68e;
  text-align: center;
  cursor: pointer;
  @include border-radius($br-radius-sm);

  &.forVM {
  
    border: 0.5px solid #ddd;

    .deleteQus {
      color: $lt-blue;
    }
  }

  .bottom {
    span {
      margin-left: 8px;
    }

    img {
      width: 12px;
      height: 10px;
      order: 1;
    }
  }
}

body:lang(en) {
  .upgradeAccount {
    .bottom {
      padding: 8px 10px;

      span {
        float: left;
        margin-top: 2px;
        margin-right: 8px;
      }

      img {
        @include transform(rotate(180deg));
      }
    }
  }
}
