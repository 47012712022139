@import '../../styles/global';
$stepbar-color: #e8eaeb;

@keyframes stepbar {
  0% {
    box-shadow: 0 0 0 0 rgba(74, 144, 226, 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(74, 144, 226, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(74, 144, 226, 0);
  }
}
.centerizeCaption {
    margin-right: -33px !important;
}
.isLtr {
  margin: 0 6% 0 2% !important;

  .stepsWrapper {
    direction: ltr;
    .circle {
      &:before {
        margin-right: 0px !important;
        margin-left: 4px !important;
      }
    }
  }
}
.stepbar {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  height: 61px;
  margin: 0 2%;
  align-items: center;
  justify-content: center;
  .stepsWrapper {
    position: absolute;
    width: 98%;
    list-style: none;
    display: flex;
    flex-basis: auto;
    justify-content: space-between;

    @media (max-width: $web-medium) {
      width: 90%;
    }

    @media (max-width: $tablet - 199) {
      width: 90%;
    }
    .step {
      position: relative;
      float: right;
      width: 100%;
      height: 3px;

      .item {
        display: block;
        // width: 160px;
        cursor: pointer;

        .circle {
          display: block;
          margin-bottom: 4px;
          width: 25px;
          height: 25px;
          border: 3px solid $stepbar-color;
          margin-top: -8px;
          background: #f9f9f9;
          z-index: 5;
          // margin-right: auto;
          // margin-left: auto;
          @include border-radius(50px);
        }

        .caption {
          color: $lt-gray;
          font-size: $font-size - 2;
          line-height: 32px;
          width: 200px;
          position: absolute;
          &:last-child {
            // right: -23px;
          }
          @media (max-width: $web-medium) {
            line-height: 2;
          }

          @media (max-width: $tablet - 199) {
            display: none;
          }
        }
      }
      .lastItem {
        width: 0 !important;
      }
      &:last-child {
        width: 0 !important;

        .caption {
          // margin-right: -80px !important;

          @media (max-width: $web-medium-max + 100) {
            margin-right: 0;
            margin-left: 0;
          }
        }

        &:after {
          width: 0;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 3px;
        z-index: -1;
        width: 100%;
        height: 3px;
        background: $stepbar-color;
      }

      &.completed,
      &.current {
        .item {
          .circle {
            border: 3px solid $lt-blue;
            background: #fff;
            background: #f9f9f9;
            background-clip: padding-box;

            &:before {
              content: '';
              display: inline-block;
              width: 11px;
              height: 11px;
              margin-right: 4px;
              margin-top: 3.5px;
              @include border-radius(50px);
            }
          }
        }
      }

      &.completed {
        .item {
          .circle {
            &:before {
              background-color: $lt-blue;
            }
          }
        }

        &:after {
          background-color: $lt-blue;
        }
      }

      &.current {
        .item {
          .circle {
            @include animation(stepbar 2s linear infinite);

            &:before {
              background-color: $stepbar-color;
            }
          }

          .caption {
            font-family: $medium-font;
          }
        }
      }
    }
  }
  .stepsWrapper2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;
    margin-right: 2%;
    margin-left: 2%;
    .step {
      position: relative;
      float: right;
      // width: 24%;

      .item {
        display: flex;
        height: 100px;
        cursor: pointer;

        .circle {
          display: block;
          width: 25px;
          height: 25px;
          border: 3px solid $stepbar-color;
          margin-top: -8px;
          background: #f9f9f9;
          z-index: 5;
          // margin-right: auto;
          // margin-left: auto;
          @include border-radius(50px);
        }

        .caption {
          color: $lt-gray;
          font-size: $font-size - 2;
          margin-right: 10px;

          @media (max-width: $web-medium) {
            line-height: 0;
          }

          @media (max-width: $tablet - 199) {
            display: none;
          }
        }
      }

      &:last-child {
        width: 0;

        .caption {
          @media (max-width: $web-medium-max + 100) {
            margin-right: 0;
            margin-left: 0;
          }
        }

        &:after {
          width: 0;
        }
      }

      &.completed,
      &.current {
        .item {
          .circle {
            border: 3px solid $lt-blue;
            background: #fff;
            background: #f9f9f9;
            background-clip: padding-box;

            &:before {
              content: '';
              display: inline-block;
              width: 11px;
              height: 11px;
              margin-right: 4px;
              margin-top: 3.5px;
              @include border-radius(50px);
            }
          }
        }
      }

      &.completed {
        .item {
          .circle {
            &:before {
              background-color: $lt-blue;
            }
          }
        }

        &:after {
          background-color: $lt-blue;
        }
      }

      &.current {
        .item {
          .circle {
            @include animation(stepbar 2s linear infinite);

            &:before {
              background-color: $stepbar-color;
            }
          }

          .caption {
            font-family: $medium-font;
          }
        }
      }
    }
  }
}

body:lang(en) {
  .stepbar {
    .stepsWrapper {
      .step {
        float: left;

        &:last-child {
          .caption {
            margin-right: 0;
            margin-left: -89px;
          }
        }

        .item {
          .caption {
            @media (max-width: $web-medium-max) {
              margin: 0;
              padding: 0;
            }
          }
        }
        &:after {
          left: 93px;
        }
      }
    }
  }
}
.horizontalLine {
  border-right: 2px solid #e8eaeb;
  height: 100%;
  right: 3.5%;
  position: absolute;
}
