@import '../../../styles/global.scss';

.cardWrapper {
  @include flex(row, wrap);
  @include choosePlanCard;
  margin-bottom: 23px;
  margin-top: 65px;
  margin-bottom: 50px;

  @media (max-width: $tablet) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
