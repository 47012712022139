@import '../../../styles/global.scss';

$box-length: 160px;
$icon-length: 60px;
$margin: 13px;
$side-length: 10px;

.checkbox {
  position: absolute;
  bottom: $side-length;
  right: $side-length;
}

.dropdown {
  position: absolute;
  bottom: 6px;
  left: $side-length;
}

.info {
  @include flex(column, wrap);
  justify-content: center;
  align-items: center;
  margin-top: $margin;

  .date {
    font-family: $light-font;
    font-size: $font-size - 4;
    color: $lt-gray;
    margin-bottom: $margin/4;
  }
}

.name {
  @include flex(row, nowrap);
  justify-content: center;
  width: 100px;
  direction: ltr;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $dk-gray;
}

.item .type,
.type img {
  width: $icon-length;
  height: $icon-length;
  object-fit: cover;
  border-radius: 3px;
  @media (max-width: $mobile - 20) {
    width: $icon-length - 10;
    height: $icon-length - 10;
  }
}

body:lang(en) {
  .item {
    @media (max-width: $mobile + 220) {
      margin: 6px 6px 0 0;
    }

    @media (min-width: $mobile + 221) and (max-width: $tablet) {
      margin: 20px 20px 0 0;
    }

    @media (min-width: $tablet + 1) and (max-width: $web-medium - 100) {
      margin: 10px 10px 0 0;
    }

    @media (min-width: $web-medium - 101) and (max-width: $web-medium-max) {
      margin: 30px 30px 0 0;
    }

    @media (min-width: $web-medium-max + 1) and (max-width: $web-medium-max + 300) {
      margin: 24px 24px 0 0;
    }
  }
}
