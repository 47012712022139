@import "../../../styles/global";

.radio {
	@include checkbox-radio-theme(radio, null);
    height: 100%;
    line-height: 100%;
	&.disableWithChecked {
		@include checkbox-radio-theme(radio, disableWithChecked);
	}

	&.disabled {
		@include checkbox-radio-theme(radio, disabled);
	}
}