/* base */
$yellow: #f5cd23;
$green: #21d352;
$red: #ff4747;
$lt-gray: #737373;
$dk-gray: #4a4a4a;
$lt-blue: #4b9cfd;
$dk-blue: #2476ff;

/* specific */
$hover-gray: #f9fbfc;
$disabled-lt-gray: #cfcfcf;
$disabled-dk-gray: #f1f2f3;
$input-border: #dfdfdf;
$input-color: #9b9b9b;
$modal-header: #f6f7f8;

$background-gray : #fbfbfb
