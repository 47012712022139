@import "../../../../styles/global";

.fileFiltering {
	.option {
		> div {
			display: inline-block;
		}
	}
}

body:lang(en) {
	.fileFiltering {
		.option {
			margin-right: 0;
			margin-left: 11px;

			.text {
				margin-right: 0;
				margin-left: 10px;
			}
		}
	}
}