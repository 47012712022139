@import '../../styles/global';
$img-size: 30px;
@media (max-width: $tablet) {
  .displayNone {
    display: none !important;
  }
  .displayYes {
    display: flex !important;
  }
}
.navbar {
  .right {
    .nav {
      .item {
        .iconLink {
          span {
            color: $dk-gray;
            margin-right: 5px;
          }
        }

        &:first-child {
          margin-right: $default-margin - 5;
        }

        &.current {
          span {
            font-family: $medium-font;
          }
        }
      }
    }
  }

  .left {
    .nav {
      .iconLink {
        &.hasNotif {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 10px;
            width: 4px;
            height: 4px;
            background-color: $red;

            @include border-radius(50%);
          }
        }

        &.status {
          background-color: #f3f7fd;

          .icon {
            margin-top: 4px;
          }
        }
      }
    }
  }
}

body:lang(en) {
  .navbar {
    .right {
      .menu {
        margin-right: 6px;
      }

      .nav {
        .item {
          margin-right: $default-margin;
          margin-left: 0;

          .iconLink {
            span {
              margin-left: 5px;
              margin-right: 0;
            }
          }

          &:first-child {
            margin-left: $default-margin - 12;
          }
        }
      }
    }

    .left {
      margin-right: $default-margin;
      margin-left: 0;

      @media (max-width: $tablet) {
        margin-right: 0;
      }

      .nav {
        .iconLink {
          margin-right: 21px;
          margin-left: 0;

          &.hasNotif {
            &:before {
              left: 5px;
            }
          }

          @media (max-width: $tablet) {
            margin-right: 10px;
            margin-left: 0;
          }
        }
      }
    }
  }
}
.notification {
  position: absolute;
  padding: 2px;
  bottom: -4px;
  right: -4px;
  height: 13px;
  width: 13px;
  color: white;
  border-radius: 50%;
  background: #e93232;
  text-align: center;
  font-size: 11px;
  line-height: 12px;
}
@media (min-width: 767px) and (max-width: 850px) {
  .item {
    margin: 0 !important;
  }
}
