$body-bcgcolor: rgba(248, 248, 248, 0.82);

/* type of fonts */
$thin-font: 'vazir-thin';
$regular-font: 'vazir-regular';
$light-font: 'vazir-light';
$medium-font: 'vazir-medium';
$bold-font: 'vazir-bold';

/* default font-size */
$font-size: 14px;



