@import '../../../../../styles/global.scss';
.selected {
  color: white !important;
}

.item {
  @include flex(column, wrap);
  flex-basis: calc(25% - 55px);
  align-items: center;
  position: relative;
  margin-bottom: 55px;
  margin-left: 55px;
  min-width: 230px;
  height: 180px;
  background-color: #fff;
  border: solid 1px #e5e5e5;
  cursor: pointer;
  @include border-radius($br-radius-sm);
  @include box-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.05));

  @media (min-width: $web-medium + 1) and (max-width: $web-medium-max) {
    margin-bottom: 3px;
    margin-left: 3px;
    flex-basis: calc(33.33% - 3px);
  }

  @media (min-width: $tablet + 1) and (max-width: $web-medium) {
    margin-bottom: 4px;
    margin-left: 4px;
    flex-basis: calc(50% - 4px);
  }

  @media (min-width: $tablet - 63) and (max-width: $tablet) {
    margin-bottom: 5px;
    margin-left: 5px;
    flex-basis: calc(33.33% - 5px);
  }

  @media (min-width: $tablet - 278) and (max-width: $tablet - 62) {
    margin-bottom: 7px;
    margin-left: 7px;
    flex-basis: calc(50% - 7px);
  }
  @media (max-width: $tablet - 180) {
    flex-basis: 100%;
    margin-bottom: 20px;
    margin-left: 0;
    .showFull {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &:hover {
    .borderFull {
      border-top: 0 !important;
      border: 1px solid #4b9cfd;
    }
  }
  &:hover,
  &.selected {
    .top {
      border: 1px solid $lt-blue;
    }
    // .footer :hover {
    //   background-color: $lt-blue;
    //   color: #fff;
    //   @include transition(background-color 1s);
    // }
    color: white !important;
    .footer .active {
      background-color: $lt-blue;
      color: #fff;
      @include transition(background-color 1s);
    }
  }

  &.activeTimed {
    .top {
      border: 0;
    }
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #21d352;
  }
  &.withoutHover {
    .top {
      border: transparent;
    }

    .footer {
      background-color: transparent;
    }
  }

  &.active {
    border: 1px solid $green;
  }

  .top {
    @include flex(column, wrap);
    align-items: center;
    justify-content: center;
    width: 100%;
    pointer-events: none;
    height: 135px;
    border: 1px solid transparent;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;

    text-align: center;
    direction: ltr;
    background-color: $background-gray;
    color: $dk-gray;
    font-family: $medium-font;
    @include box-shadow(0 -1px 0 0 rgba(0, 0, 0, 0.05));
  }
  .active {
    height: 45px;
    line-height: 45px;
  }
  .height {
    height: 45px;
    line-height: 45px;
  }

  &.selected .active {
    background-color: $lt-blue;
    color: #fff;
    @include transition(background-color 1s);
  }
}

body:lang(en) {
  .item {
    margin-left: 55px;
    margin-right: 0;

    @media (min-width: $web-medium + 1) and (max-width: $web-medium-max) {
      margin-bottom: 3px;
      margin-right: 3px;
      margin-left: 0;
      flex-basis: calc(33.33% - 3px);
    }

    @media (min-width: $tablet + 1) and (max-width: $web-medium) {
      margin-bottom: 4px;
      margin-right: 4px;
      margin-left: 0;
      flex-basis: calc(50% - 4px);
    }

    @media (min-width: $tablet - 63) and (max-width: $tablet) {
      margin-bottom: 5px;
      margin-right: 5px;
      margin-left: 0;
      flex-basis: calc(33.33% - 5px);
    }

    @media (min-width: $tablet - 278) and (max-width: $tablet - 62) {
      margin-bottom: 7px;
      margin-right: 7px;
      margin-left: 0;
      flex-basis: calc(50% - 7px);
    }
    @media (max-width: $tablet - 277) {
      flex-basis: 100%;
      margin-bottom: 20px;
      margin-right: 0;
      margin-left: 0;
    }
  }
}
