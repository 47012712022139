@import '../../../styles/global';

.langSwitch {
  display: block;
  margin: 0 auto;
  width: 55px;
  height: 20px;
  border: 1px solid $dk-blue;
  text-align: center;
  line-height: 15px;
  @include border-radius($br-radius-lg);

  .item {
    display: inline-block;
    font-size: $font-size - 4;
    color: $dk-blue;
    text-decoration: none;
    cursor: pointer;
    &.active {
      width: 25px;
      height: 14px;
      margin-top: 2px;
      background-color: $dk-blue;
      color: #fff;
      text-align: center;
      cursor: unset;
      @include border-radius(78.4px);
    }

    &:last-child {
      margin-right: 4.5px;
    }
  }
}

body:lang(en) {
  .langSwitch {
    .item {
      &:last-child {
        margin-left: 4.5px;
      }
    }
  }
}
