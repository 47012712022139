@import '../../../styles/global';

@mixin top {
  top: auto;
  bottom: 100%;
  border-top: 0;
  border-bottom: 6px solid $lt-gray;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

@mixin right {
  right: 100%;
  bottom: auto;
  margin: 0;
  border-right: 6px solid $lt-gray;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

@mixin bottom {
  top: 100%;
  bottom: auto;
  border-bottom: 0;
  border-top: 6px solid $lt-gray;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

@mixin left {
  right: auto;
  bottom: auto;
  left: 100%;
  margin: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid $lt-gray;
}

.tooltip {
  @include flex(column, wrap);
  position: relative;
  // z-index:1000;
  align-items: center;
  cursor: pointer;
  font-family: $medium-font;
  
  &.top {
    flex-direction: column-reverse;

    .text {
      bottom: 100%;
      top: auto;
      &:after {
        @include bottom;
      }
    }
  }

  &.right {
    @include flex(row, wrap);
    .text {
      top: -5px;
      left: 100%;
      margin-left: 5px;
      &:after {
        @include right;
      }
    }
  }

  &.left {
    @include flex(row, wrap);
    .text {
      top: -5px;
      right: 100%;
      margin-right: 5px;
      &:after {
        @include left;
      }
    }
  }

  &:hover {
    .text {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .hasTooltip {
    display: flex;
    align-items: center;
    width: 100%;
  }

 .text {
    display: none;
    position: absolute;
    top: 100%;
    margin-top: 7px;
    margin-bottom: 7px;
    padding: 0;
    color: #fff;
    background-color: $lt-gray;
    text-align: center;
    font-size: 10px;
    z-index: 3;
    @include border-radius($br-radius-sm);
    @include box-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.15));

    &:after {
      content: '';
      position: absolute;
      right: 50%;
      width: 0;
      height: 0;
      margin-right: -5.5px;
      @include top;
    }
  }
}
