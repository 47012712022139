@import '../../styles/global';

.wrapper {
  @include flex(column, nowrap);
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f9fafc;

  .logo {
    width: 227px;
    margin-bottom: 30px;
  }

  .mainBox {
    width: 500px;
    padding: 45px 100px 38px 100px;
    margin-bottom: 21px;
    background-color: #fff;
    text-align: center;
    @include border-radius($br-radius-sm);
    @include box-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.15));

    @media (max-width: $tablet) {
      width: 300px;
      padding: 30px 0 38px 0;
      background-color: transparent;
      @include box-shadow(none);
    }

    a {
      text-decoration: none;
    }

    .login {
      input {
        @include box-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.15));
        margin-bottom: 8px;
      }
    }

    .title,
    .description {
      color: $dk-gray;
    }

    .title {
      line-height: 28px;
      font-size: $font-size + 2;
      letter-spacing: -0.3px;
    }

    .description {
      margin-bottom: 38px;
      font-size: $font-size - 2;
      letter-spacing: -0.2px;
    }

    .row {
      @include flex(row, wrap);
      justify-content: space-between;
      height: 35px;
      margin-top: 14px;
      color: $dk-gray;

      .switch {
        @include flex(row, wrap);
        justify-content: center;
        align-items: center;

        a {
          margin-right: 3px;
          color: #4a90e2;
        }
      }
    }

    .buttonLoading {
      position: absolute;
      left: 28px;
      top: 5px;
    }

    .forgetPassword {
      // display: block;
      // margin-top: 36px;
      display: flex;
      align-items: center;
      font-size: $font-size - 2;
      color: $input-color;

      img {
        width: 10px;
        margin-left: 5px;
      }
    }
    .noMargin {
      margin-top: 0 !important;
    }

    .warn {
      background-color: rgba(255, 71, 71, 0.05);
      border: solid 1px $red;
      color: $red;
    }
    .success {
      background-color: rgba(33, 211, 82, 0.1);
      border: solid 1px $green;
      color: $green;
    }
    .wrongVerify {
      line-height: 35px;
      margin-top: 20px;
      padding-right: 10px;
      padding-left: 10px;
      font-size: $font-size - 4;
      text-align: right;
      @include border-radius($br-radius-sm);

      img {
        width: 14px;
        margin-left: 7px;
      }
    }
  }
}

body:lang(en) {
  .wrapper {
    .mainBox {
      .row {
        .switch {
          a {
            margin-left: 3px;
          }
        }
      }

      .forgetPassword {
        img {
          margin-right: 3px;
          transform: rotateY(-180deg);
        }
      }

      .wrongVerify {
        text-align: left;

        img {
          margin-right: 7px;
          margin-left: 0;
        }
      }
    }
  }
}
