@import '../../../styles/global';

@mixin widthShadow {
  @include box-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.15));
}

.row {
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(to top, #f7f7f7, #ffffff);
  width: 100%;
  border-radius: 3px;
  @include widthShadow;
  button {
    min-width: 46px;
    height: 35px;
    font-size: 11px;
    cursor: pointer;
  }
  .btnGroup {
    background: 0;
    font-family: 'vazir-medium';
    border: 0;
    border-left: solid 0.5px #dfdfdf;
    color: #737373;
    min-width: 46px;
    height: 35px;
    font-size: 11px;
    cursor: pointer;
  }
  .active {
    background-color: $lt-blue;
    color: white;
    border-left: $lt-blue;
    padding: 1px 4px;
    border-radius: 3px;
  }
  .btnGroup:last-child {
    border-left: 0;
  }
}
.dropdown {
  display: flex;
  padding: 0 10% 0 0;
  &.btnSuccess0 {
    .caretSpan {
      width: 35px;
      height: 35px;
      border-radius: 3px;
      border-right: 0;
      background-image: linear-gradient(to top, #24c064, #2ecf6c);
    }
  }
  .caretSpan {
    position: absolute;
    left: 0;
    width: 35px;

    height: 33px;
    img {
      transform: rotate(-90deg);
      margin-top: 3px;
    }
  }
}
.leftie {
  position: absolute;
  left: 0;
  width: 100%;

  height: 33px;
  ul {
    margin-top: 35px;
  }
}
.disable {
  pointer-events: none;
}
@media (max-width: $tablet) {
  .btnCircle {
    width: 30px !important;
    height: 30px !important;
    position: absolute;
  }
  .left {
    left: 3px;
  }
  .right {
    right: 3px;
  }
}
