/* border-radius */
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

/* box-shadow */
@mixin box-shadow($box-shadow) {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

/* display flex */
@mixin flex($direction, $wrap-status) {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap-status;
}

/* transform */
@mixin transform($value) {
  -webkit-transform: $value;
  -moz-transform: $value;
  -ms-transform: $value;
  -o-transform: $value;
  transform: $value;
}

/* transition */
@mixin transition($value) {
  -webkit-transition: $value;
  -moz-transition: $value;
  -o-transition: $value;
  transition: $value;
}

/* animation */
@mixin animation($value) {
  -webkit-animation: $value;
  -moz-animation: $value;
  -o-animation: $value;
  animation: $value;
}

/* button-theme */
@mixin btn-theme($background, $btn-color, $border) {
  background: $background;
  color: $btn-color;
  border: $border;
}

/* input-theme */
@mixin input-theme {
	position: relative;
	width: 200px;
	height: 35px;
	background: #fff;
	color: $lt-gray;
	font-family: $regular-font;
	@include border-radius($br-radius-sm);
}

/* input-placeholder */
@mixin input-placeholder($search) {
	&::-webkit-input-placeholder {
		color: $input-color;
		@if $search {
			font-size: $font-size;
		}
	}

	&:-moz-placeholder {
		color: $input-color;
		@if $search {
			font-size: $font-size;
		}
	}

	&:-ms-input-placeholder {
		color: $input-color;
		@if $search {
			font-size: $font-size;
		}
	}
}

/* checkbox-radio-theme */
@mixin checkbox-radio-theme($type, $className) {
	position: relative;
	cursor: pointer;

	> input {
		position: absolute;
		height: 0;
		width: 0;
		opacity: 0;
		cursor: pointer;

		&:checked ~ .checkmark {
			background-color: $dk-blue;
			border: 1px solid $dk-blue;

			&:after {
				display: block;
			};
		}
	}

	.checkmark {
		display: inline-block;
		width: 13px;
		height: 13px;
		background-color: #fff;
		border: 0.8px solid $disabled-lt-gray;
		@if $type == checkbox {
			@include border-radius(2.4px);
		} @else if $type == radio {
			@include border-radius(6.5px);
		}

		&:after {
			content: '';
			display: none;
			position: absolute;
			@include transform(rotate(45deg));
			@include transition(all ease-in-out 0.2s);

			@if $type == checkbox {
				right: 4px;
				width: 5px;
				height: 9px;
				border-style: solid;
				border-color: #fff;
				border-width: 0 1.5px 1.5px 0;
			} @else if $type == radio {
				top: 6px;
				right: 5px;
				width: 3px;
				height: 3px;
				background: #fff;
				@include border-radius(50px);
			}
		}
	}

	@if $className == disableWithChecked or $className == disabled  {
		cursor: not-allowed;
	}

	@if $className == disableWithChecked {

		.disableWithChecked:checked ~ .checkmark {
			background: $disabled-lt-gray;
		}

		.checkmark {
			border: 1px solid $disabled-lt-gray $important;
			background-color: $disabled-dk-gray $important;

			&:after {
				@if $type == checkbox {
					border-color: $disabled-lt-gray $important;
				} @else {
					background: $disabled-lt-gray $important;
				}
			}
		}
	}

	@if $className == disabled {

		.checkmark {
			background-color: $disabled-dk-gray;
		}
	}
}
