@import '../../../styles/global.scss';

.item {
  position: relative;
  width: 100%;
  font-family: sans-serif;
  padding: 13px 10px 10px;
  background-color: #fff;
  @include border-radius($br-radius-sm);
  @include box-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.05));
  border: solid 1px #e5e5e5;
}

