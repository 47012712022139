@import '../../../styles//global.scss';
$header-height: 45px;

@keyframes popout {
  from {
    @include transform(scale(0));
  }
  80% {
    @include transform(scale(1.2));
  }
  to {
    @include transform(scale(1));
  }
}

@keyframes opacity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  opacity: 0;

  .modalMain {
    position: fixed;
    top: 50%;
    left: 50%;
    background: #fff;
    height: auto;
    z-index: 1000;
    @include border-radius($br-radius-sm);
    @include box-shadow(0 5px 15px 0 rgba(0, 0, 0, 0.1));
    @include transform(translate(-50%, -50%));

    @media(min-width: $mobile + 1) and (max-width: $tablet - 128) {
      width: 375px $important;
    }    

    @media(max-width: $mobile) {
      width: 320px $important;
    }
  }

  .header {
    @include flex(row, wrap);
    @include box-shadow(0 1px 0 0 #e5e5e5);
    @include border-radius($br-radius-sm);
    justify-content: space-between;
    height: $header-height;
    background: $modal-header;
  

    .title {
      @include flex(row, wrap);
      margin-right: 23px;
      align-items: center;
      font-family: $medium-font;
      color: $lt-gray;
    }

    .close {
      cursor: pointer;
      border: 0;
      background: 0;
      margin: $header-height/4;
      justify-self: flex-end;
    }
    .closeIcon {
      width: 10px;
    }
  }

  .body {
    padding: 0 14px;
  }
}
.closeIcon {
  width: 10px;
}
.displayBlock {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s;
  animation: popout 350ms ease;
}

.displayNone {
  visibility: hidden;
}

body:lang(en) {
  .modal {
    .header {
      .title {
        margin-left: 10px;
      }
    }
  }
}
