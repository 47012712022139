/*
 * Define the widths: play around with these to get a best fit.
 */
$text-width: 180px;
$num-width: 120px;

// Main container: initialize the flex, direction is row
.table-row {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-grow: 0;
  -webkit-flex-grow: 0;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: center;
  width: 100%;
  //   padding-left: 15px;
  //   padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

// Text based cells: grow, truncate and show ellipsis when there is not enough space
.text {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  //   padding-right: 20px;
}

// Fix the minimum width of the leaf level cells
.text {
  width: $text-width;
}
.share {
  width: 222px;
}
.num {
  width: $num-width;
}
.name {
  cursor: pointer;
}

/*
  * General good-look styles, not mandatory.
  */

.table-row {
  border-bottom: 1px solid #e5e5e5;
  border-collapse: collapse;
  // cursor: pointer !important;
  //    padding-top: 5px;
}

.table-row.header {
  //    background-color: #FFEEDB;
  color: #9b9b9b;
  font-weight: bold;
  padding-top: 8px;
  padding-bottom: 8px;
}
.header {
  .num,
  .text,
  .share {
    cursor: pointer;
  }
}

// .tooltip{
//   display: none;
  // direction: ltr;
  // font-size: 10px;
  // border-radius: 9px;
  // padding: 2px 10px;
  // margin-top: -17px;
  // margin-right: 10px;
  // color: white;
  // z-index: 1000;
  // background: #80808036;
// }
// .text:hover .tooltip {
//   display: block;
//   position: fixed;
//   overflow: hidden;
// }
// .tooltip{
//   display: none;
// }

// .text .tooltip {
//   visibility: hidden;
//   width: 120px;
//   background-color: black;
//   color: #fff;
//   text-align: center;
//   border-radius: 6px;
//   padding: 5px 0;
//   position: fixed;
//   transform:translate(50px,-50px);
//   z-index: 1000;
//   bottom: 100%;
//   left: 50%;
//   margin-left: -60px;

//   /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
//   opacity: 0;
//   transition: opacity 1s;
// }
// .text {
//   position: relative;

// }
// .text:hover .tooltip {
//   visibility: visible;
//   opacity: 1;
// }

.text {
  // color: red;
  cursor: pointer;
}

.text:hover .tooltip {
  display: block;
}

.tooltip {
  display: none;
  position: fixed;
  padding: .5em 1em;
  border-radius: 5px;
  color:#9c9c9cd9;
  font-size: 10px;
  background-color: #f1f1f1;
  z-index:1000;
}