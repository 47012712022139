@import '../../../styles/global.scss';

$width: 20px;
$height: 12px;
.knobActiveboth {
  background-color: $dk-blue !important;
}

.knobLeft:before {
  content: '';
  top: 3px;
  right: 44px;
  width: 10px;
  height: 10px;
  background-color: #e5e7e9;
}
.bigToggle {
  $width: $width * 2 + 5;
  $height: $height * 2;
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
  vertical-align: middle;

  input[type='checkbox'] {
    display: none;
  }

  .knob {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $disabled-lt-gray;
    cursor: pointer;
    @include transition(all 0.4s);
    @include border-radius($height);

    &:before {
      content: '';
      position: absolute;
      top: 2.5px;
      left: 4.8px;
      width: ($height - 7);
      height: ($height - 7);
      background-color: #fff;
      @include transition(all 0.4s);
      @include border-radius(50%);
    }
  }

  input:checked + .knob {
    background-color: $dk-blue;
  }

  input:checked + .knob:before {
    @include transform(translateX($height - 4));
  }

  &.onDisabled {
    .knob {
      background-color: $disabled-lt-gray $important;
    }

    input:checked + .knob:before {
      transform: translateX($height - 4);
    }
  }

  &.disabled {
    .knob {
      cursor: not-allowed;
      background: $disabled-dk-gray;
    }
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
  vertical-align: middle;

  &.vmSwitch {
    width: 38px;
    height: 16px;
    @include border-radius(8.5px);

    .knob {
      background-color: #fff;
      @include box-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.05));

      &:before {
        content: '';
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        background-color: #e5e7e9;
      }

      &:after {
        position: absolute;
        right: 4px;
        bottom: -1px;
        content: 'OFF';
        color: $lt-gray;
        font-size: $font-size - 4;
      }
    }

    input:checked + .knob {
      background-color: #fff;
    }

    input:checked + .knob:before {
      @include transform(none);
      background-color: $green;
    }

    input:checked + .knob:after {
      content: 'ON';
      right: 6.5px;
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  .knob {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $disabled-lt-gray;
    cursor: pointer;
    @include transition(all 0.4s);
    @include border-radius($height);

    &:before {
      content: '';
      position: absolute;
      top: 2.3px;
      left: $height * 0.18;
      width: ($height - 4);
      height: ($height - 4);
      background-color: #fff;
      @include transition(all 0.4s);
      @include border-radius(50%);
    }
  }

  input:checked + .knob {
    background-color: $dk-blue;
  }

  input:checked + .knob:before {
    @include transform(translateX($height - 4));
  }

  &.onDisabled {
    .knob {
      background-color: $disabled-lt-gray $important;
    }

    input:checked + .knob:before {
      transform: translateX($height - 4);
    }
  }

  &.disabled {
    .knob {
      cursor: not-allowed;
      background: $disabled-dk-gray;
    }
  }
}
