@import '../../styles/global.scss';

.breadcrumb {
  @include flex(row, wrap);
  justify-self: flex-start;
  align-items: center;
  flex: 3;
  flex-direction: row-reverse;
  direction: ltr;
  @media (max-width: $tablet) {
    font-size: $font-size - 2;
  }

  .item {
    color: $input-color;
    text-decoration: none;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    &:first-child {
      margin-right: 0;
      margin-left: 9px;
      margin-right: 7px;

      @media (max-width: $tablet) {
        margin-left: 7px;
      }
    }
  }

  img {
    width: 6px;
  }

  .active {
    color: $dk-gray;
    pointer-events: none;
  }
}
.modalbread {
  width: 100%;
  margin-bottom: 23px;
}

body:lang(en) {
  .breadcrumb {
    flex-direction: row;
    direction: ltr !important;
    .item {
      margin-left: 10px;

      &:first-child {
        margin-right: 9px;
        margin-left: 0;
      }
    }

    img {
      @include transform(rotate(180deg));
    }
  }
}
.isOver {
  background: #55de3e30;
  padding: 2px 5px;
  border-radius: 3px;
}
