@import '../../../styles/global';

@keyframes up-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.signout {
	display: none;
	position: absolute;
	top: 55px;
	left: 27px;
	
	list-style: none;
	background: #fff;
	opacity: 0;
	@include box-shadow(0px 8px 16px 0px rgba(0,0,0,0.2));
	@include border-radius($br-radius-sm);

	&.open {
		display: block;
		opacity: 1;
		animation: up-opacity 400ms;
	}

	@media(max-width: $tablet) {
		top: 63px;
		left: 14px;
	}

	&:before {
		position: absolute;
		top: -4px;
		left: 14px;
		content: '';
		width: 8px;
		height: 8px;
		background: $modal-header;
		@include border-radius(1px);
		@include transform(rotate(134deg));
	}

	li {
	
		
		&.item {
			> div {
				img {
					width: 16px $important;
					height: 16px $important;
					margin-top: 0 $important;
					margin-left: 6px;
				}

				span {
					font-size: $font-size - 2;
				}
			}

			&:hover {
				background: $hover-gray;
			}
		}

		&:first-child {
			margin-bottom: 1px;
			background: $modal-header;
			@include box-shadow(0 1px 0 0 #e5e5e5);
		}
	}
}

body:lang(en) {
	.signout {
		right: 14px;
		left: auto;

		&:before {
			right: 26px;
			left: auto;

			@media(max-width: $tablet) {
				right: 14px;
			}
		}

		li {
			&.item {
				> div {
					margin-right: 0 $important;

					img {
						margin-right: 6px;
						margin-left: 0;
					}
				}
			}
		}
	}
}