/* all links with icon (IconLink component) */
body {
  position: fixed;
  width: 100%;
  height: 100%;
  .main {
    height: calc(100vh - 115px) !important;
    overflow: auto;
    // width: 100%;
  }
  .mainNoscroll {
    height: calc(100vh - 115px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.iconq {
  width: 17px !important;
  height: 17px !important;
}
.iconLink {
  cursor: pointer;

  img {
    vertical-align: middle;
  }
}
.fa {
  font-family: $light-font;
}
.react-fine-uploader-gallery-dropzone {
  border-radius: 3px !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05) !important;
  border: solid 1px #e5e5e5 !important;
  background-color: #ffffff !important;
}
.react-fine-uploader-file-progress-bar {
  background: #00abc7;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) inset;
  height: inherit;
  border-radius: 9px;
  direction: rtl;
}
.red {
  background: #ff4747;
}
.yellow {
  background: #f5cd23;
}
.green {
  background: #21d352;
}
.react-fine-uploader-file-progress-bar-container {
  height: 5px;
  left: 50%;
  opacity: 0.9;
  top: 60px;
  width: 90%;
  z-index: 1;
  direction: ltr;
  background: #f1f1f2;
  width: 100%;
}
.react-fine-uploader-thumbnail {
  width: 28px;
  height: 28px;
}
.chartHeight {
  height: 120px !important;
  width: 90%;
}
.rowItem {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  position: relative;
  .imageIcon {
    width: 28px;
    height: 28px;
    object-fit: cover;
    border-radius: 3px;
  }
  .icon {
    margin-right: 21px;
    margin-left: 15px;

    @media (max-width: $tablet) {
      margin-right: 15px;
    }
  }
}
.smPadding {
  padding: 10px 7px !important;
  border-bottom: 0 !important;
}
/* hide items */
.hide {
  display: none;
}

.tooltipIcon {
  margin: 6px 0 0;
}

/* networkInfo */
.networkInfo {
  width: 100%;
  padding: 5px;

  .header {
    color: $input-color;
    font-family: $medium-font;
  }

  .header,
  .body {
    @include flex(row, wrap);

    > div {
      width: 50%;
      line-height: 50px;
    }
  }

  .body {
    color: $dk-gray;
  }
}

/* m choosePlanCard */
@mixin choosePlanCard {
  > div {
    display: block;
    text-align: center;
  }

  footer {
    direction: rtl;
  }

  .planInfo {
    padding: 12px 15px;

    .name {
      color: $dk-gray;
      font-family: $medium-font;
      font-size: $font-size + 2;
    }

    .trafic {
      margin-top: -3px;
      font-size: $font-size - 4;
      color: $lt-gray;
    }

    .softwareInfo {
      margin-top: 14px;
      text-align: right;

      .row {
        @include flex(row, wrap);
        justify-content: space-between;
        font-size: $font-size - 2;
        color: $dk-gray;
      }
    }
  }
}

// @media print {
//   body > :not(.print-parent):not(.print-me) {
//     display: none;
//   }

//   .print-parent > :not(.print-parent):not(.print-me) {
//     display: none;
//   }
// }
//   body * {
//     visibility: hidden;
//   }

//   html, body {
//     height:100%;
//     margin: 0 !important;
//     padding: 0 !important;
//     overflow: hidden;
//   }
//   #section-to-print,
//   #section-to-print * {
//     visibility: visible;
//   }
//   #section-to-print {
//     position: absolute;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 10%;
//     border: 0;
//     left: 0;
//     top: 0;
//   }
// }
.centerize {
  margin: 0 auto;
}
.warn {
  background-color: rgba(255, 71, 71, 0.05);
  border: solid 1px $red;
  color: $red;
}
.success {
  background-color: rgba(33, 211, 82, 0.1);
  border: solid 1px $green;
  color: $green;
}
.wrongVerify {
  line-height: 35px;
  margin-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 10px;
  text-align: right;
  border-radius: 3px;

  img {
    width: 14px;
    margin-left: 7px;
  }
}
.noFocus {
  background: linear-gradient(to top, #f7f7f7, #fff) !important;
}
.diagonalLine {
  width: 86px;
  height: 47px;
  border-bottom: 2px solid red;
  -webkit-transform: translateY(-33px) translateX(-23px) rotate(-15deg);
  position: absolute;
}
.dropzone {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%234C7AB7DE' stroke-width='5' stroke-dasharray='13%2c 20%2c 22' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  height: 500px;
  justify-content: center;
  align-items: center;
}
