@import '../../../styles/global';
.textInputEn {
  font-family: sans-serif !important;
}
.noMargin {
  margin: 0 !important;
}
@mixin type-input($type) {
  .textInput {
    border: 1px solid;

    @if $type == error {
      border-color: $red;
    } @else if $type == success {
      border-color: $green;
    } @else if $type == disabled {
      background-color: $disabled-dk-gray;
      color: $input-color;
      border-color: #d6d7d7;
    }
  }
}
.onErr {
  border-color: $red !important;
}
.inputWrapper {
  position: relative;

  .label {
    display: block;
    color: $dk-gray;
    font-size: $font-size - 2;
    font-family: $medium-font;
  }

  .textInput {
    @include input-theme;
    @include input-placeholder(false);
    width: 100%;
    border: 1px solid $input-border;
    padding-top: 8px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 8px;
    margin-bottom: 6px;
    font-size: $font-size - 2;

    &:hover {
      border: 1px solid $disabled-lt-gray;
    }

    &:focus {
      border: 1px solid $lt-blue;
    }

    &.hasIcon {
      padding-right: 36px;
    }
  }

  &.error {
    @include type-input(error);
  }

  &.success {
    @include type-input(success);
  }

  &.disabled {
    @include type-input(disabled);
  }

  .shoowErorrMsg {
    display: block;

    > img {
      width: 15px;
      height: 15px;
      margin-left: 6px;
      vertical-align: middle;
    }

    > span {
      color: $red;
      font-size: $font-size - 4;
    }
  }

  .withIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 16px;
  }
}

body:lang(en) {
  .inputWrapper {
    .textInput {
      &.hasIcon {
        padding-right: 0;
        padding-left: 36px;
      }
    }

    .withIcon {
      right: 0;
      left: 10px;
    }
  }
}
