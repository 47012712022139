@import '../../styles/global.scss';

$border-table: #e5e5e5;
$table-header-color: #9b9b9b;
$border-bottom: 1.5px solid $border-table;
.verticalCenter {
  vertical-align: middle;
}
.noBorder {
  th,
  td {
    border-bottom: 0 !important;
    padding: 6px !important;
  }
}
.table {
  border-collapse: collapse;
  padding: 0;
  width: 100%;
  z-index: -100;

  th,
  td {
    text-align: left;
    border-bottom: $border-bottom;
    color: $dk-gray;
  }

  .activeRow {
    background-color: #f7f9fa;
  }
  tr {
    cursor: pointer;

    td:last-child {
      text-align: left;

      > div {
        @media (max-width: $mobile) {
          margin-right: -44px;
          width: 87px;
        }
      }
    }
  }
  .invisible {
    visibility: hidden;
  }
  .share {
    position: absolute;
    top: 50%;
    margin: auto;
    left: 43%;
    padding: 3px 15px;
    transform: translate(-50%, -50%);
  }
  tbody {
    td {
      padding: 19px 0;

      @media (max-width: $tablet) {
        padding: 16px 0;
      }
    }
  }
  td {
    text-align: right;
    height: 23px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .header {
    .name {
      margin: 20px;
    }
    color: $table-header-color;
  }
  .name {
    max-width: 300px;
    justify-content: center;
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: $medium-font;
  }
  .sort {
    display: flex;
    flex-direction: column;
    margin: 0 3px;
    width: 30px;
    span {
      line-height: 6px;
      font-size: 6px;
    }
  }
}

@media screen and (max-width: $tablet + 100px) {
  .table {
    td {
      display: none;
    }

    thead {
      border-bottom: $border-bottom;

      td {
        padding-top: 37px;
        padding-bottom: 20px;

        &:first-child {
          font-family: $medium-font;
        }
      }
    }
    .more {
      float: right;
    }
    .left {
      padding: 0 $important;
      text-align: left;
    }
    .header {
      border: 0;
    }
    .show {
      display: table-cell;

      .icn {
        img {
          vertical-align: middle;
          margin-left: 6px;
        }

        span {
          font-family: $medium-font;
        }
      }
    }
  }
}

body:lang(en) {
  .table {
    tr {
      td {
        @media screen and (max-width: $tablet) {
          &:last-child {
            text-align: right;

            > div {
              @media (max-width: $mobile) {
                width: 108px;
                margin-right: 0;
                margin-left: -58px;
              }
            }
          }
        }
      }
    }

    .icn {
      img {
        margin-right: 6px;
      }
    }
  }
}
.expandIcon {
  transform: rotate(-90deg);
  width: 7px;
}
.collapseIcon {
  transform: rotate(90deg);
  width: 7px;
}

.droppableRow {
  background: #e6f6e2e0;
}
.draggingRow{
  // cursor: move !important;
  background: #d5e7fca6;
}
#123{
  // cursor: move !important;
}