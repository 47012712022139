@import '../../../../styles/global.scss';
.overFlow {
  max-height: 240px;
  overflow-y: scroll;
}
.uploadModal {
  @include flex(column, wrap);
  color: $dk-gray;
  width:100%;

  justify-content: center;
  align-items: center;
  .row {
    @include flex(row, wrap);
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 20px 0 20px;
  }
  .column {
    @include flex(column, wrap);
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 15px 0 48px;
  }
}
.displayBlock {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s;
  animation: popout 350ms ease;
}
.displayNone {
  visibility: hidden;
}
.thumbnail {
  width: 20px !important;
  height: 20px !important;
  margin: 5px;
  object-fit: cover;
}
.progressBar {
  width: 100px;
  max-width: 100px;
  justify-content: center;
  text-align: center;
}
.uploadingModal {
  position: fixed;
  width: 310px;

  background: white;
  bottom: 4%;
  left: 2%;

  border-radius: 3px;
  .header {
    background-color: #4b9cfd;
    color: white;
    padding: 2%;
    display: flex;
    justify-content: space-between;
    border-radius: 3px 3px 0 0;
  }
  .body {
    .name {
      width: 100%;
      text-align: right;
      max-width: 400px;
      align-items: center;
      justify-content: center;
      font-size: 11px;
    }
    .file {
      align-items: center;
      padding: 2%;
      display: flex;
      justify-content: space-between;
    }
  }
}
