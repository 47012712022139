.overlay {
  position: absolute;
  height: 100%;
  min-height: fit-content;
  background-color: rgba(211, 211, 211, 0.43);
  border-radius: 3px;
  width: 100%;
  left: 0;
    top: 0;
  z-index: 2;
  pointer-events: none;
}
.loading {
  position: absolute;
  left: -50%;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 100%;
  z-index: 2;
}
