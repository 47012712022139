.billing {
  td {
    padding: 13px 0 !important;
    font-size: 14px;
  }
  thead {
    td {
        padding:  0 !important;
      }
    }
}
