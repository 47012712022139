@import '../../styles/global';

.videoPlayer {
  background: #ffffff;
  padding: 30px;
  height: 100%;
  border-radius: 3px;
  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 4px;
  }
  .controlBar {
    display: flex;
    border-radius: 3px;
    border: solid 1px #bbbdbf;
    background-color: #ffffff;
    align-items: center;
    padding: 8px;
    justify-content: space-around;
    button {
      background-color: #ffffff;
      border: 0;
      cursor: pointer;
    }

    .play {
      margin-left: 8px;
      margin-right: 8px;
      cursor: pointer;
    }
  }
}

.audioPlayer {
  .details {
    margin: 60px 10px;
  }
  .info {
    margin: 0 20px;
  }
  .marginLittle {
    margin: 0 2px;
  }
  .description {
    color: $lt-gray;
  }
}
@media (max-width: $tablet) {
  .videoPlayer {
    padding: 10px;
    
  }
  .player {
    overflow: hidden;
    height: 400px;
    object-fit: cover;
    max-height: 400px;
    margin-top: 20px;
    margin-bottom: 25px;
  }
}
@media (max-width: $tablet -200) {
  
  .player {
    overflow: hidden;
    height: 300px;
    object-fit: cover;
    max-height: 300px;
    margin-top: 20px;
    margin-bottom: 5px;
  }
}
