@import '../../../styles/global.scss';
.left {
  direction: left;
}
.allBoxes {
  // width: 64%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4a4a4a;

  @media (max-width: 1200px) {
    // width: 100%;
  }
}
.planInfo {
  padding: 12px 15px;
  width: 100%;
  .name {
    color: $dk-gray;
    font-family: $medium-font;
    font-size: $font-size + 2;
  }

  .trafic {
    margin-top: -3px;
    font-size: $font-size - 4;
    color: $lt-gray;
  }

  .softwareInfo {
    margin-top: 14px;
    text-align: right;

    .row {
      @include flex(row, wrap);
      justify-content: space-between;
      font-size: $font-size - 2;
      color: $dk-gray;
    }
  }
}
.cardWrapper {
  @include flex(row, wrap);
  @include choosePlanCard;
}

.stepbarWrapper {
  height: 45px;
  margin-top: 23px;
  margin-bottom: 52px;
  margin-right: 46px;
  margin-left: 46px;
  // width: 86%;
  @media (max-width: $mobile) {
    margin-top: 25px;
    margin-bottom: -5px;
  }
}

.stepDescription {
  @include flex(row, unset);
  // margin-right: 2%;
  // width: 50%;
  // margin-bottom: 46px;

  @media (max-width: $web-medium-max) {
    width: 100%;
  }

  @media (max-width: $mobile) {
    // margin-bottom: 20px;
  }

  .number {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 10px;
    width: 41px;
    height: 41px;
    border: solid 1px $dk-gray;
    font-size: $font-size + 8;
    @include border-radius(50%);

    @media (max-width: $mobile) {
      width: 32px;
      height: 32px;
      font-size: $font-size + 6;
    }
  }

  .text {
    .title {
      font-size: $font-size + 2;
      color: $dk-gray;
      display: flex;
      flex-direction: column;

      @media (max-width: $mobile) {
        font-size: $font-size - 2;
      }
    }

    .subTitle {
      font-size: $font-size - 2;
      color: $lt-gray;
      @media (max-width: $mobile) {
        font-size: $font-size - 5;
      }
    }
  }
}

.serviceDuration {
  .cardWrapper {
    img {
      width: 68px;
      height: 60px;
    }
  }
}

.choosePlan {
  .choosePlanCard {
    @include choosePlanCard;
  }
}

.chooseNetworkCard {
  .wrapper {
    margin: 0 0 145px;

    @media (max-width: $tablet) {
      margin: 0 0 30px;
    }
  }
}
.redText {
  color: $red !important;
}
.settingBox {
  @include flex(row, wrap);
  justify-content: space-between;
  align-items: center;
  width: 650px;
  height: $navbar-height;
  margin: 0 auto 30px auto;
  padding: 0 20px;
  background-color: #fff;
  border: solid 1px #dbdbdb;
  @include border-radius($br-radius-sm);

  .right {
    @media (max-width: 570px) {
      > div {
        display: inline-block;
      }

      .name {
        margin-left: 8px;
      }
    }

    .name {
      color: $dk-gray;
      font-size: $font-size + 2;
      font-family: $medium-font;
    }

    .info {
      color: $dk-blue;
    }
  }

  .center {
    margin: 0 34px;
    flex-grow: 1;

    > div {
      padding-bottom: 10px;

      @media (max-width: 570px) {
        margin-top: 39px;
        margin-left: -119px;
      }
    }
  }

  .left {
    font-size: $font-size - 2;
    color: $lt-gray;
  }

  @media (min-width: $tablet + 1) and (max-width: $web-medium) {
    width: 450px;

    .center {
      margin: 0 7px;
      > div {
        > input {
          width: 270px $important;
        }
      }
    }
  }

  @media (min-width: 641px) and (max-width: $tablet) {
    width: 600px;

    .center {
      margin: 0 16px;
    }
  }

  @media (min-width: 571px) and (max-width: 640px) {
    width: 500px;

    .center {
      > div {
        > input {
          width: 260px $important;
        }
      }
    }
  }

  @media (max-width: 570px) {
    padding-bottom: 15px;
  }

  @media (min-width: $mobile) and (max-width: 570px) {
    width: 345px;

    .center {
      > div {
        > input {
          width: 300px $important;
        }
      }
    }
  }

  @media (max-width: $mobile - 1) {
    width: 290px;

    .center {
      > div {
        > input {
          width: 244px $important;
        }
      }
    }
  }
}
.finalPrice {
  font-size: 20px;
  width: 100%;
  justify-content: flex-end;
  margin-left: 20px;

  align-items: center;
  display: flex;
}
.expandIcon {
  transform: rotate(-90deg);
  width: 7px;
}
.collapseIcon {
  transform: rotate(90deg);
  width: 7px;
}
.FinalStep {
  .wrapper {
    // margin: 0 0 124px;

    @media (max-width: $tablet) {
      margin: 0 0 50px;
    }
  }

  .caption {
    color: $dk-gray;
    line-height: 32px;
  }

  .dscp {
    color: $lt-gray;
    font-size: $font-size - 2;
    margin-top: -4px;
  }

  .serverName {
    padding: 6px;
    width: 100%;

    .dscp {
      margin-bottom: 18px;
    }

    input {
      @media (max-width: $mobile + 65) {
      }
    }
  }

  .discount {
    padding: 6px;
    width: 100%;
    // border-left: 1px solid;
    .dscp {
      margin-bottom: 18px;
    }

    input {
      width: 174px;
      height: 35px;
    }

    button {
      margin-right: 10px;

      @media (max-width: $mobile + 65) {
        margin-right: 3px;
      }
    }
  }
}
.dropDown {
  height: 40px;
  display: flex;
  background: #3a92fb;
  border-right: 1px solid #3a92fb;
  border-radius: 3px 0 0 3px;
  color: white;
  width: 150px;
  justify-content: center;
  align-items: center;
}
.osImg {
  // width: 58px;
  padding: 6px;

  // border-right: 1px solid #e8eaeb;
}
.activeBackground {
  background-color: #3a92fb;
  color: white;
  pointer-events: none;
  border-radius: 3px;
}
.bg {
  height: 40px;
  border-left: 1px solid #e8eaeb;
  width: 40px;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.selectos {
  color: gray;
  border: 1px solid #e8eaeb;
}
body:lang(en) {
  .settingBox {
    @media (max-width: 570px) {
      flex-direction: row-reverse;
    }
    .right {
      @media (max-width: 570px) {
        .name {
          margin-right: 8px;
        }
      }
    }
  }

  .stepDescription {
    margin-right: 0;
    margin-left: 50px;

    @media (max-width: $tablet) {
      margin-left: 0;
    }

    .number {
      margin-right: 9px;
      margin-left: 0;
    }
  }

  .FinalStep {
    .discount {
      button {
        margin-left: 10px;
        margin-right: 0;

        @media (max-width: $mobile + 65) {
          margin-left: 3px;
        }
      }
    }
  }
}
