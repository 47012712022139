@import '../../../styles/global.scss';
$header-height: 83px;
$margin-top: 70px;
$preview-header-color: #8b8e9b;
$padding: 30px;
.image {
  height: 100%;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .cover {
    object-fit: cover;
  }
}
.marginLeft {
  display: flex;
  margin-left: 23px;
}
.marginRight {
  margin-right: 23px;
  display: flex;
}

.row {
  @include flex(row, unset);

  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 900;
  opacity: 0;

  .modalMain {
    position: fixed;
    top: 50%;
    left: 50%;
    background: #fff;
    height: auto;
    z-index: 1000;
    @include border-radius($br-radius-sm);
    @include box-shadow(0 5px 15px 0 rgba(0, 0, 0, 0.1));
    @include transform(translate(-50%, -50%));

    @media (min-width: $mobile + 1) and (max-width: $tablet - 128) {
      width: 375px $important;
    }

    @media (max-width: $mobile) {
      width: 320px $important;
    }
  }
}
.previewBody {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  border-radius: 3px;
}
.displayBlock {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s;
  animation: popout 350ms ease;
}

.displayNone {
  visibility: hidden;
}
.previewBox {
  margin-top: $navbar-height;
  margin-right: $sidebar-width;
  height: calc(100% - 70px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;

  .header {
    @include flex(row, wrap);
    justify-content: space-between;
    align-items: center;
    height: $header-height;
    padding: 0 $padding;
    width: 100%;
    background-color: $preview-header-color;
    .fileName {
      color: white;
      font-size: 16px;
    }
    .icon {
      vertical-align: middle;
      font-family: 'vazir-medium';
      img {
        margin: 10px;
      }
    }
  }
  .flexBar {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .bottomBar {
    @include flex(row, wrap);
    justify-content: center;
    .icon {
      margin-bottom: -4px;
      margin: 4px;
    }
  }
  @media (max-width: 768px) {
    .bottomBar {
      display: none !important;
    }
    .rightArrow {
      right: 2%;
    }
    .leftArrow {
      left: 2%;
    }
    // .arrows {
    //   position: absolute;
    // }
    // .arrowRight {
    //   right: 2%;
    // }
    // .arrowLeft {
    //   left: 2%;
    // }
  }
  .arrows {
    @include flex(row, unset);
    width: 100%;
    height: 60%;
    justify-content: space-around;
    align-items: center;
    section {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
    }
    .icon {
      margin-bottom: 1px;
      width: 50%;
    }
    .iconRight {
      @include transform(rotate(180deg));
    }
  }
}

@media (max-width: $tablet) {
  .previewBox {
    margin-top: 0 !important;
  }
  .modal {
    .previewBody {
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      margin: 10px;
      margin-top: 20px;
      width: 80%;
    }
    .previewBox {
      margin-right: 0 !important;
    }
    .previewMain {
      width: 100%;
    }
    .icon {
      margin-bottom: -2px !important;
    }
    .arrows {
      height: auto;
      section {
        width: 100%;
        max-height: 399px;
      }
    }
  }
  .image {
    display: flex;
    justify-content: center;
    width: 100%;

    align-content: center;
    .cover {
      width: 95%;
      margin-top: 5px;
    }
  }
}
@media (max-width: $tablet +200) {
  .modal {
    .arrows {
      justify-content: space-evenly;
    }
  }
}

.arrows {
  section {
    @media (max-height: 700px) {
      object-fit: cover;
      overflow: hidden;
      margin-top: 20px;
    }
    @media (max-height: 700px) and(min-height: 550px) {
      height: 200px !important;
      margin-bottom: 10px;
    }
    @media (max-height: 549px) {
      height: 100px !important;
    }
  }
}
