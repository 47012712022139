@import '../../../../styles/global.scss';

.move {
  width: 100%;
  height: 264px;
  overflow: scroll;
}
.submitButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
 
}
.loading{
  width: 100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected{
  border: 2px solid #d8e4f8;
}