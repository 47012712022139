@import '../../styles/global.scss';

.windowsImg {
  width: 450px !important;
  height: 382px !important;
}
.windowsInput {
  position: absolute;
  right: 94px;
  top: 144px;
  background: 0;
  color: blue;
}
.windowsAdministrator {
  position: absolute;
  right: 94px;
  top: 170px;
  background: 0;
  color: blue;
}
.VMDetails {
  @media (max-width: $mobile) {
    margin-top: -20px;

    > div {
      margin-left: 0;
    }
  }

  .choosePlanCard {
    @include choosePlanCard;
  }

  .cardWrapper {
    @include flex(row, wrap);
    margin-top: 65px;
    margin-bottom: 50px;

    @media (max-width: $tablet) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .upgradeFooter {
    @include flex(row, wrap);

    .vmInfo {
      @include flex(row, wrap);
      justify-content: space-between;
      color: $dk-gray;
      flex-basis: calc(100% - 263px);

      @media (max-width: 500px) {
        flex-basis: 100%;
        @include flex(column, wrap);
      }

      .value {
        color: $dk-gray;
        font-family: $medium-font;
      }

      .label {
        color: $lt-gray;
        font-family: $light-font;
      }
    }

    .inBtn {
      img {
        margin-left: 9px;
      }
    }

    button {
      @media (max-width: 500px) {
        margin-top: 24px;
        width: 100% $important;
      }
    }
  }
}
.wrapper {
  margin-top: 25px;
  padding: 15px;
  background-color: #fbfbfb;
  border: solid 1px $input-border;
  @include border-radius($br-radius-sm);

  @media (max-width: $mobile + 125) {
    margin-top: 10px;
  }

  &.deleteVM {
    @include flex(row, wrap);
    justify-content: space-between;

    .right {
      @media (max-width: $tablet) {
        margin-bottom: 15px;
      }
    }

    .left {
      display: flex;

      img {
        margin-left: 8px;
      }
      > button {
        margin-right: 32px;

        @media (max-width: $mobile) {
          margin-right: 4px;
        }
      }

      @media (max-width: $mobile) {
        width: 100%;

        > div {
          display: none;
        }

        button {
          width: 100% $important;
        }
      }
    }
  }

  .title {
    color: $dk-gray;
    font-family: $medium-font;
  }

  .hint {
    margin-top: 3px;
    color: $lt-gray;
    font-size: $font-size - 2;
  }
}
body:lang(en) {
  .VMDetails {
    .wrapper {
      &.deleteVM {
        .left {
          img {
            margin-right: 8px;
            margin-left: 0;
          }

          > button {
            margin-right: 0 $important;
            margin-left: 32px;

            @media (max-width: $tablet) {
              margin-left: 4px;
            }
          }
        }
      }
    }

    .upgradeFooter {
      .inBtn {
        img {
          margin-right: 9px;
        }
      }
    }
  }
}
