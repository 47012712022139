@import '../../../styles/global.scss';

.control {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2px;
    margin-left: 2px;

    width: 25px;
    height: $default-height;
    @include border-radius(3px);
    background-image: linear-gradient(to top, #f7f7f7, #ffffff);
    border: solid 1px #e6e6e6;
  }

  &.off {
    button {
      border: 1px solid $red;
      background: linear-gradient(to top, #f94926, #fd6b4b);
    }
  }
}

.multipleControl,
.multiplePlan {
  @include flex(row-reverse, unset);
  justify-content: space-between;
}

.multiplePlan {
  width: 100%;
  button {
    font-size: $font-size - 2;
    @media (max-width: $tablet - 180) {
      width: 100%;
    }
    line-height: 12px;
  }
}

body:lang(en) {
  .multiplePlan {
    button {
      font-size: $font-size - 4;
    }
  }
}

.disabled {
  pointer-events: none;
}
