@import '../../styles/global.scss';

$arrow-width: 16px;
.flex {
  display: flex;
  &:nth-child(2) {
    margin-left: 20px;
  }
}
.undo {
  color: green;
  margin-right: 10px;
  cursor: pointer;
}
.loading {
  width: 100%;
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
}
.row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  button {
    margin-right: 15px;
  }
}
.header {
  @include flex(row, wrap);
  margin-bottom: 10px;

  @media (max-width: $tablet) {
    margin-bottom: 0;
  }

  .left {
    display: flex;

    @media (max-width: $tablet) {
      display: none;
    }

    > div {
      &:first-child {
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 30px;
      }
    }

    .viewMode {
      @include flex(column, wrap);
      justify-content: center;
      align-items: center;
      img {
        cursor: pointer;
      }
    }
  }
}

.footer {
  @include flex(row, wrap);
  margin-top: 100px;
  justify-content: center;

  .arrow {
    color: $input-color;
    img {
      margin-left: 15px;
      vertical-align: middle;
    }
  }
}

body:lang(en) {
  .header {
    .left {
      > div {
        &:first-child {
          margin-right: 20px;
        }
        &:last-child {
          margin-right: 0;
          margin-left: 30px;
        }
      }
    }
  }

  .footer {
    .arrow {
      img {
        margin-right: 15px;
      }
    }
  }
}
