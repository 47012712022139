@import "../../../styles/global";
$background-gray: #e8eaeb;
$track-height: 10px;

.styled_range {
  position: relative;
    display: flex;

  input[type="range"] {
    -webkit-appearance: none;
    background: transparent;
    position: absolute;
    
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: $track-height;
    }
  }

  .track_range {
    pointer-events: none;
    overflow: hidden;
    border-radius: 3px;
    height:10px;
    background-color: #e8eaeb !important;
    
    @-moz-document url-prefix() {
        margin-top: 4px;
    }
    &::-webkit-slider-runnable-track {
      background: $background-gray;
      border-radius: $br-radius-sm;
      overflow: hidden;
    }
  
    &::-moz-range-progress{
      background: $background-gray;
      border-radius: $br-radius-sm;
      overflow: hidden;
    }
    &::-moz-progress{
      background: $background-gray;
      border-radius: $br-radius-sm;
      overflow: hidden;
    }
    &::-moz-range-thumb{
      @include box-shadow(-600px 0 0 600px $green);
      -webkit-appearance: none;
      background: transparent;
      height: 1px;
      width: 1px;
    }
    &::-webkit-slider-thumb {
      @include box-shadow(-600px 0 0 600px $green);
      -webkit-appearance: none;
      background: transparent;
      height: 1px;
      width: 1px;
    }
  }

  .thumb_range {
    &::-webkit-slider-runnable-track {
      background: transparent;
      cursor: pointer;
    }
    &::-moz-range-progress {
      background: transparent;
      cursor: pointer;
    }
    &::-moz-range-thumb{
      -webkit-appearance: none;
      border-radius: $track-height * 2;
      height: 20px;
      width: 20px;
    
      background: url("../../../images/Icons/rangebarIcon/icon-range-slider-holder.svg")
        center center no-repeat;
      cursor: pointer;
      margin: 0;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      border-radius: $track-height * 2;
      height: $track-height * 2;
      width: $track-height * 2;
      background: url("../../../images/Icons/rangebarIcon/icon-range-slider-holder.svg")
        center center no-repeat;
      cursor: pointer;
      margin: -($track-height/2) 0px 0px 0px;
    }
  }
}