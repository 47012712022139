@import '../../../styles/global';
$border-rds-size: 40px;

.progressContainer {
  position: relative;
  direction: ltr;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  progress {
    background-color: #eee;
    width: 100%;
    height: 40px;
    overflow: hidden;

    @include border-radius($border-rds-size);
  }

  progress::-webkit-progress-bar {
    background-color: #eee;
    @include border-radius($border-rds-size);
  }

  progress::-webkit-progress-value {
    @include border-radius($border-rds-size);
  }
  .blue::-webkit-progress-value {
    background-color: $lt-blue !important;
  }
  .red::-webkit-progress-value {
    background-color: $red !important;
  }

  .green::-webkit-progress-value {
    background-color: $green !important;
  }
  .green::-moz-progress-bar {
    background-color: $green !important;
  }
  .yellow::-webkit-progress-value {
    background-color: $yellow !important;
  }
  .gray::-webkit-progress-value {
    background-color: $input-color !important;
  }
}
