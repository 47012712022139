/* pages padding responsive mode */
$rsp-padding: 15px;

/* sidebar */
$sidebar-width: 260px;

/* navbar */
$navbar-height: 70px;

/* default ui-elements */
$default-height: 27px;
$br-radius-lg: 100px;
$br-radius-sm: 3px;

/* default padding & margin */
$default-padding: 30px;
$default-margin: 30px;

/* responsive resolution */
$mobile: 375px;
$tablet: 768px;
$web-medium: 992px;
$web-medium-max: 1200px;
/* general */
$important: !important;