@import '../../../../styles/global';

.downloadBar {
  background: white;

  display: none;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  margin-top: 23px;
  align-items: center;
  padding: 20px 0;
  .downloadBox {
    @include flex(row, wrap);
    justify-content: center;
    font-size: 12px;
    padding: 8px;
    border-radius: 3px;
    border: solid 0.5px #dddddd;
    width: 200px;
    margin-bottom: 15px;
  }
}
@media (max-width: $tablet) {
  .downloadBar {
    display: flex;
    margin: 0 !important;
  }
}
@media (max-height: 750px) and (min-height: 487px) {
  .downloadBar {
    padding: 0;
  }
}

// @media (max-height: 486px) {
//   .downloadBar {
//     display: none;
//   }
// }
