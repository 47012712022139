@import '../../../styles/global.scss';
.border {
  border: solid 1px #e5e5e5;
  @include box-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.05));
  margin-top: 45px;
  @media (max-width: $web-medium-max+200px) {
    margin-left: 4px;
  }
  @media (min-width: $web-medium-max+200px) {
    margin-left: 20px;
  }
}
.shadow {
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e5e5e5;
  padding: 13px 10px 10px;
  margin-top: 45px;
  @media (max-width: $web-medium-max) {
    margin-left: 4px;
  }
  @media (min-width: $web-medium-max) {
    margin-left: 20px;
  }
}
.height {
  min-height: auto !important;
  padding: 20px !important;
}
.item {
  position: relative;
  min-width: 230px;
  min-height: 175px;
  font-family: sans-serif;
  flex-basis: 23%;
  padding: 13px 10px 10px;
  background-color: #fff;
  @include border-radius($br-radius-sm);

  @media (min-width: $web-medium + 40) and (max-width: $web-medium-max) {
    margin-top: 3px;
    margin-left: 3px;
    flex-basis: calc(33.33% - 3px);
  }

  @media (min-width: $tablet + 1) and (max-width: $web-medium+39) {
    margin-top: 4px;
    margin-left: 4px;
    flex-basis: calc(50% - 4px);
  }

  @media (min-width: 1199px) and (max-width: 1400px) {
    min-width: 300px;
    flex-basis: 32%;
  }

  @media (min-width: $tablet - 63) and (max-width: $tablet) {
    margin-top: 5px;
    margin-left: 5px;
    flex-basis: calc(33.33% - 5px);
  }

  @media (min-width: $tablet - 278) and (max-width: $tablet - 62) {
    margin-top: 7px;
    margin-left: 7px;
    flex-basis: calc(50% - 7px);
  }

  @media (min-width: $mobile) and (max-width: $tablet - 277) {
    min-width: 320px;
    margin-top: 20px;
    margin-left: 0;
    justify-content: center;

    &:first-child {
      margin-top: 0;
    }
  }

  @media (max-width: $mobile - 1) {
    min-width: 290px;
    margin-top: 20px;
    margin-left: 0;
  }

  .show {
    display: block;
    justify-content: center;
    .right {
      flex-basis: 32%;
    }

    .left {
      flex-basis: 66%;
    }
  }

  .showFull {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    .center {
      width: 70%;
    }
    button {
      width: 41px;
      height: 41px;
    }
    .footer {
      margin: 0;
      width: 360px;
    }
    .right {
      flex-basis: 58%;
    }

    .left {
      flex-basis: 42%;
    }
  }
  @media (max-width: $tablet - 180) {
    .showFull {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .center {
      width: 100% !important;
    }
  }
  .showDetails {
    @include flex(row, wrap);
    justify-content: space-between;
    margin-top: 20px;

    @media (max-width: $mobile + 130) {
      flex-direction: column-reverse;
    }

    .multipleCtrWrapper {
      margin: 0;

      @media (min-width: $mobile) and (max-width: $mobile + 130) {
        margin: 18px auto 0 auto;

        > div {
          width: 323px $important;
        }
      }

      @media (max-width: $mobile - 1) {
        margin: 18px auto 0 auto;

        > div {
          width: 267px $important;

          > div {
            button {
              width: 40px;
            }
          }
        }
      }
    }

    .center {
      margin: 0;

      @media (max-width: $mobile + 130) {
        justify-content: normal;
      }

      .right,
      .left {
        flex-basis: auto;
      }
    }
  }

  .top {
    @include flex(row, wrap);
    justify-content: space-between;

    .iconWrapper {
      margin: 16px auto 0px auto;
      img {
        width: 50px;
      }
    }

    .title {
      font-family: sans-serif;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .subTitle {
      color: $lt-gray;
      font-size: $font-size - 2;
    }

    .state {
      @include flex(row, wrap);
      justify-content: space-between;
      align-items: center;
      padding: 0 5px;
      min-width: 38px;
      align-self: flex-end;
      height: 16px;
      margin-bottom: 10px;
      background-color: #fff;
      @include border-radius(8.5px);
      @include box-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.05));

      .lamp {
        display: inline-block;
        width: 10px;
        height: 10px;
        @include border-radius(50%);

        &.on {
          background-color: $green;
        }

        &.off {
          background-color: #e5e7e9;
        }

        &.problem {
          background-color: $yellow;
        }
      }

      .status {
        margin-left: 3px;
        color: $lt-gray;
        font-size: $font-size - 4;
        letter-spacing: -0.2px;
      }
    }
  }

  .center {
    direction: ltr;
    @include flex(row, wrap);
    // justify-content: space-between;
    margin: 17px 0 14px 0;

    .description {
      font-family: $medium-font;
      margin: 0 auto;
      padding-top: 8px;
      // padding-bottom:10px;
      color: $lt-gray;
      font-size: $font-size - 2;
    }

    .right,
    .left {
      > div {
        margin-top: 4px;
        > img {
          margin-right: 5px;
        }

        > span {
          color: $dk-gray;
          font-size: $font-size - 2;
        }
      }
    }
  }

  .footer {
    @include flex(row, unset);
    justify-content: space-between;

    @media (max-width: $web-medium-max) {
      margin: 0 auto;
    }
    @media (max-width: 700px) {
      width: auto !important;
    }
    @media (min-width: $mobile) and (max-width: $tablet - 277) {
    }

    @media (max-width: $mobile - 1) {
    }

    &.oneBtn {
      justify-content: center;
      a,
      button {
        @media (max-width: $tablet - 277) {
          width: 100%;
        }
      }
    }

    &.twoBtn {
      @media (min-width: $mobile) and (max-width: $tablet - 277) {
        button {
          &:first-child {
            width: 180px $important;
          }
          &:nth-child(2) {
            width: 100px $important;
          }
        }
      }

      @media (max-width: $mobile - 1) {
        button {
          &:first-child {
            width: 160px $important;
          }
          &:nth-child(2) {
            width: 100px $important;
          }
        }
      }
    }

    button {
      font-size: $font-size - 2;

      > div {
        > img {
          margin-left: 8px;
        }
      }
    }

    .cloneSteps {
      margin: 6px auto -6px auto;
      font-size: $font-size - 2;
      color: $lt-gray;
      direction: ltr;
    }

    .progressbar {
      width: 100%;
    }
  }
}

body:lang(en) {
  .item {
    margin-right: 45px;
    margin-left: 0;

    @media (min-width: $web-medium + 1) and (max-width: $web-medium-max) {
      margin-right: 3px;
    }

    @media (min-width: $tablet + 1) and (max-width: $web-medium) {
      margin-right: 4px;
    }

    @media (min-width: $tablet - 63) and (max-width: $tablet) {
      margin-right: 5px;
    }

    @media (min-width: $tablet - 278) and (max-width: $tablet - 62) {
      margin-right: 7px;
    }

    @media (max-width: $tablet - 277) {
      margin-right: 0;
    }

    .top {
      .state {
        flex-direction: row-reverse;
      }
    }

    .footer {
      button {
        > div {
          > img {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
.buy {
  width: 300px;
}
