@import '../../../styles/global';

.checkbox {
  @include checkbox-radio-theme(checkbox, null);
  display: flex;
  width: 13px;
  &.disableWithChecked {
    @include checkbox-radio-theme(checkbox, disableWithChecked);
  }

  &.indeterminate {
    .checkmark {
      &:after {
        top: 6px;
        right: 3px;
        width: 7px;
        height: 0;
        border-width: 0 0 2px 0;
        @include transform(none);
      }
    }
  }
}
