@import '../../../styles/global.scss';

.colorfulBox {
  display: flex !important;
  width: 100% !important;
  position: relative;
  @include flex(row, wrap);
  padding: 10px 12px 10px 10px;
  font-family: $medium-font;
  opacity: 1;

  &.hide {
    display: none !important;
    opacity: 0;
    @include transition(opacity 500ms);
  }

  .__message {
    flex-basis: calc(100% - 29px);
    font-family: $medium-font;
  }

  .close {
    position: absolute;
    display: flex;
    align-items: center;
    left: -2px;
    width: 29px;
    justify-content: flex-start;
    background-color: transparent;
    padding: 0;

    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  &.white {
    @include box-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.15));
    background-color: #fff;
  }
  &.wheat {
    border: solid 1px #dfdfdf;
    background-color: #fbfbfb;
  }

  &.red {
    color: $red;
    border: 1px solid $red;
    background-color: #fedfdf;
  }

  &.blue {
    color: $dk-blue;
    border: 1px solid $lt-blue;
    background-color: rgba(75, 156, 253, 0.03);
  }

  &.green {
    color: #19b444;
    border: 1px solid $green;
    background-color: rgba(33, 211, 82, 0.1);
  }
  &.ylw {
    color: #f2bf27;
    border: 1px solid #f2bf27;
    background-color: #f2bf2729;
  }
  &.ltgreen {
    background-color: $background-gray;
  }

  &.sm {
    min-height: $default-height;
    line-height: $default-height;
    @include border-radius(20px);
    justify-content: center;
  }

  &.lg {
    width: 100%;
    min-height: 50px;
    font-size: $font-size - 2;
    align-items: center;

    justify-content: space-between;
    @include border-radius($br-radius-sm);
  }
}

body:lang(en) {
  .colorfulBox {
    padding: 10px 10px 10px 12px;

    .close {
      position: absolute;
      right: 2px;
      left: auto;
    }
  }
}
