@import '../../../styles/global';

body:lang(en) {
  .main {
    margin-right: 0;
    margin-left: $sidebar-width;

    @media (max-width: $tablet) {
      margin-left: 0;
    }
  }
}
.scrollLock {
  position: fixed;
  overflow: hidden;
}
