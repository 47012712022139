@import "../../styles/global";
@import "./Card/Card.module.scss";

$title-margin-bottom: 20px;

.container {
  @include flex(row, wrap);
  position: relative;
  @media(max-width: $mobile - 20) {
    justify-content: center;
  }
}

.header {
  padding-top: 26px;

  .title {
    @include flex(row, wrap);
    color: $input-color;
    align-items: center;
    margin-bottom: $title-margin-bottom;

    .label {
      margin-right: 10px;
      font-family: $medium-font;
    }
  }
  .sort {
    @include flex(column, wrap);
    margin: 0 3px;
    width: 10px;
    span {
      line-height: 6px;
      font-size: 1px;
    }
  }
}

body:lang(en) {
  .header {
    .title {
      .label {
        margin-left: 10px;
      }
    }
  }
}