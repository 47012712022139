@import '../../../../styles/global.scss';
.value {
    font-weight: 200;
  }
.footer {
  margin-bottom: 20px;
  
  .btnWrapper {
    @include flex(row, wrap);
    justify-content: space-between;
    align-items: center;
    margin: 51px 0;

    @media (max-width: $mobile) {
      margin: 30px 0;
    }

    .right {
      display: flex;
      a {
        > button {
          margin-left: 20px;
          color: $lt-gray;

          img {
            margin-left: 9px;

            @include transform(rotate(180deg));
          }
        }
      }
    }

    .left {
      .largBtn {
        @media (max-width: $mobile) {
          width: 85px;

          button {
            width: 100% $important;

            > img {
              @media (max-width: $mobile) {
                display: none;
              }
            }

            > span {
              @media (max-width: $mobile) {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 69px;
                white-space: nowrap;
                display: block;
              }
            }
          }
        }
      }

      .wrapper {
        button {
          direction: ltr;

          img {
            margin-right: 6px;
          }
        }
      }
    }
  }

  .info {
    @include flex(row, wrap);
    color: $lt-gray;
    width: 100%;

    .item {
      width: 20%;
      margin-bottom: 14px;
      margin-top: 2px;

      @media (max-width: $web-medium - 132) {
        width: 100%;
        margin-bottom: 0;
      }

      ul {
        li {
          margin-top: 12px;
        }
      }

      .value {
        color: $dk-gray;
        font-weight: 200 !important;
      }
    }
  }
}

body:lang(en) {
  .footer {
    .btnWrapper {
      .right {
        a {
          > button {
            margin-right: 20px;
            margin-left: 0;

            &:first-child {
              width: 100px;
              direction: ltr;

              img {
                margin-right: 5px;
                margin-left: 0;
                @include transform(rotate(0deg));
              }
            }

            @media (max-width: $mobile) {
              margin-right: 10px;
            }
          }
        }
      }

      .left {
        button {
          > div {
            direction: rtl;

            img {
              margin-left: 4px;
              margin-right: 0;
              @include transform(rotate(180deg));
            }
          }
        }
      }
    }
  }
}
