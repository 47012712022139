@import '../../../styles/global.scss';

$dropdown-width: 140px;
$dropdown-item-height: 33px;
$padding: 30px;
$dropdown-border: #e5e5e5;
$left: 10px;
$more-button: #9b9b9b;
$more-button-size: 28px;
$background-white: #fff;
$margin-top: -20px;

.dropdownBox {
  position: relative;


  .more {
    margin-top: $margin-top;
  }

  // .more::after {
  //   content: '...';
  //   line-height: 12px;
  //   font-size: $more-button-size;
  //   display: flex;
  //   justify-content: center;
  // }

  .dpButton {
    border: 0;
    padding: 0;
    background: 0;
    color: $more-button;
  }

  .dpButton:hover {
    color: $lt-blue;
    cursor: pointer;
  }

  .dropdown {
    @include flex(column, wrap);
    @include border-radius($br-radius-sm);
    @include box-shadow(0 1px 3px 0 rgba(0, 0, 0, 0.05));
    text-align: right;
    position: absolute;
    z-index: 4;
    width: $dropdown-width;
    list-style: none;
    background: white;
    border: solid 1px $dropdown-border;
    left: -$left;
    font-size: 12px;

    li {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $lt-gray;
      padding: 0 $padding;

      a {
        width: 100%;
        align-items: center;
        display: flex;
        height: 35px;
      }
    }
   
    li:hover {
      background: $hover-gray;
    }
    .selected {
      background: $hover-gray;
    }

    a:link {
      color: $lt-gray;
      text-decoration: none;
      border-bottom: 0;
      width: 100%;
    }
    .label {
      line-height: 2.5;
      padding:0;
      margin: relative;
    }
    .description {
      font-family: $light-font;
      font-size: 8px;
      margin-bottom:9px;

    }
    .bordered {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      &:last-child {
        border: 0;
      }
    }
 
    .checkmark {
      display: inline-block;
      position: relative;
      top: 30%;
      &:after {
        content: '';
        display: block;
        width: 3px;
        height: 6px;
        border: solid 0.8px $lt-gray;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    .text {
      margin-right: 17px;
    }
  }
}
.relative {
  position: relative !important;
  left:0 !important;
}
body:lang(en) {
  .dropdownBox {
    text-align: right;

    .dropdown {
      text-align: left;
      right: 0;
      left: auto;
    }
  }
}