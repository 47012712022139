@import '../../../styles/global';

body:lang(en) {
	.mainFooter {
		.switcher {
			right: 30px;
			left: auto;

			@media(max-width: $mobile + 100) {
				right: 0
			}
		}
	}
}