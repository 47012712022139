@import '../../../styles/global.scss';

.header {
  @include flex(row, wrap);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  &__cstBread {
    flex: none;
    flex-basis: 23%;

    @media (max-width: $web-medium) {
      flex-basis: 50%;
    }
  }
}
