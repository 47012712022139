@import '../../../styles/global.scss';

$border-table: #e5e5e5;
$table-header-color: #9b9b9b;
$border-bottom: 1.5px solid $border-table;
.verticalCenter {
  vertical-align: middle;
}
.noBorder {
  th,
  td {
    border-bottom: 0 !important;
    padding: 6px !important;
  }
}
.table {
  border-collapse: collapse;
  padding: 0;
  width: 100%;
  z-index: -100;

  th,
  td {
    text-align: left;
    border-bottom: $border-bottom;
    color: $dk-gray;
  }

  .activeRow {
    background-color: #f7f9fa;
  }
  tr {
    cursor: pointer;

    td:last-child {
      text-align: left;

      > div {
        @media (max-width: $mobile) {
          margin-right: -44px;
          width: 87px;
        }
      }
    }
  }
  .invisible {
    visibility: hidden;
  }
  .share {
    position: absolute;
    top: 50%;
    margin: auto;
    left: 43%;
    padding: 3px 15px;
    transform: translate(-50%, -50%);
  }
  tbody {
    td {
      padding: 19px 0;

      @media (max-width: $tablet) {
        padding: 16px 0;
      }
    }
  }
  td {
    text-align: right;
    height: 23px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .header {
    .name {
      margin: 20px;
    }
    color: $table-header-color;
  }
  .name {
    max-width: 300px;
    justify-content: center;
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: $medium-font;
  }
  .sort {
    display: flex;
    flex-direction: column;
    margin: 0 3px;
    width: 30px;
    span {
      line-height: 6px;
      font-size: 6px;
    }
  }
}
.header {
  .name {
    margin: 20px;
  }
  color: $table-header-color;
}
.name {
  max-width: 300px;
  justify-content: center;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: $medium-font;
}
.sort {
  display: flex;
  flex-direction: column;
  margin: 0 3px;
  width: 30px;
  cursor: pointer;
  span {
    line-height: 6px;
    font-size: 6px;
  }
}
@media screen and (max-width: $tablet + 100px) {
  .hidden {
    display: none;
  }
  thead {
    border-bottom: $border-bottom;

    td {
      padding-top: 37px;
      padding-bottom: 20px;

      &:first-child {
        font-family: $medium-font;
      }
    }
  }
  .more {
    float: right;
  }
  .left {
    padding: 0 $important;
    text-align: left;
  }
  .header {
    border: 0;
  }
  .show {
    display: flex;

    .icn {
      img {
        vertical-align: middle;
        margin-left: 6px;
      }

      span {
        font-family: $medium-font;
      }
    }
  }
}

body:lang(en) {
  .table {
    tr {
      td {
        @media screen and (max-width: $tablet) {
          &:last-child {
            text-align: right;

            > div {
              @media (max-width: $mobile) {
                width: 108px;
                margin-right: 0;
                margin-left: -58px;
              }
            }
          }
        }
      }
    }

    .icn {
      img {
        margin-right: 6px;
      }
    }
  }
}
.expandIcon {
  transform: rotate(-90deg);
  width: 7px;
}
.number {
  // background: #aecff5;
  // color: white;
  // font-weight: 900;
  // border-radius: 50%;
  z-index: 9999;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // // padding: 2px 4px;
  // font-size: 11px;
  // line-height: 14px;
  // width: 16px;
  // height: 16px;
  // top: -6px;
  // right: -6px;
  // position: absolute;
  // left:0;
  // top:0;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 6px 4px;
  line-height: 6px;
  background: #3f97fd;
  color: white;
  text-align: center;
  font-weight: 900;
  font-size: 11px;
  top: -6px;
  right: -6px;
  position: absolute;
}
.collapseIcon {
  transform: rotate(90deg);
  width: 7px;
}
.cardDragged {
  position: absolute;
  // background:blue;
  transform-origin: bottom left;
  // z-index:1000;
  -webkit-backface-visibility: hidden;
}

.droppableRow {
  width: 100%;
  background: #e6f6e2e0;
}
.isDropping {
  background: #cbf1c1;
  width: 100%;
}
.draggingRow {
  cursor: move !important;
  background: #d5e7fca6;
}
#123 {
  cursor: move !important;
}

$table-header: #1976d2;
$table-header-border: #1565c0;
$table-border: #d9d9d9;
$row-bg: #f4f2f1;

div {
  box-sizing: border-box;
}

.tableContainer {
  display: block;
  margin: 2em auto;
  width: 90%;
  max-width: 600px;
}

.flag-icon {
  margin-right: 0.1em;
}
.rowClass {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.flexTable {
  display: flex;
  flex-flow: row wrap;
  border-left: solid 1px $table-border;
  transition: 0.5s;
  &:first-of-type {
    border-top: solid 1px $table-header-border;
    border-left: solid 1px $table-header-border;
  }
  &:first-of-type .flex-row {
    background: $table-header;
    color: white;
    border-color: $table-header-border;
  }
  &.row:nth-child(odd) .flex-row {
    background: $row-bg;
  }
  &:hover {
    background: #f5f5f5;
    transition: 500ms;
  }
}

.flexRow {
  width: calc(100% / 4);
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px $table-border;
  border-bottom: solid 1px $table-border;
}

.rowspan {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.column {
  display: flex;
  flex-flow: column wrap;
  width: 75%;
  padding: 0;
  .flex-row {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 0;
    border: 0;
    border-bottom: solid 1px $table-border;
    &:hover {
      background: #f5f5f5;
      transition: 500ms;
    }
  }
}

.flex-cell {
  width: calc(100% / 3); //1px = border right
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px $table-border;
  //flex: 1 1 33.3%;
  &:last-child {
    // border-right: 0;
  }
}

@media all and (max-width: 767px) {
  .flex-row {
    width: calc(100% / 3); //1px = border right

    &.first {
      width: 100%;
    }
  }

  .column {
    width: 100%;
  }
}

@media all and (max-width: 430px) {
  .flex-table {
    .flex-row {
      border-bottom: 0;
    }
    .flex-row:last-of-type {
      border-bottom: solid 1px $table-border;
    }
  }

  .header {
    .flex-row {
      border-bottom: solid 1px;
    }
  }

  .flex-row {
    width: 100%; //1px = border right

    &.first {
      width: 100%;
      border-bottom: solid 1px $table-border;
    }
  }

  .column {
    width: 100%;
    .flex-row {
      border-bottom: solid 1px $table-border;
    }
  }

  .flex-cell {
    width: 100%; //1px = border right
  }
}
.invisible {
  visibility: hidden;
}
.share {
  position: absolute;
  top: 50%;
  margin: auto;
  left: 43%;
  padding: 3px 15px;
  transform: translate(-50%, -50%);
}
