@import '../../../styles/global.scss';

$box-length: 160px;
$icon-length: 60px;
$margin: 13px;
$side-length: 10px;

.item {
  @include flex(column, nowrap);
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 160px;
  height: $box-length;
  margin: 32px 0 0 32px;
  background-color: #fff;
  cursor: pointer;
  @include box-shadow(0 1px 2px 0 rgba(0, 0, 0, 0.1));
  @include border-radius(2.6px);

  @media (max-width: $mobile - 20) {
    min-width: 139px;
    height: 150px;
  }

  @media (max-width: $mobile + 220) {
    flex-basis: calc(50% - 6px);
    margin: 6px 3px 0 3px;
  }

  @media (min-width: $mobile + 221) and (max-width: $tablet) {
    flex-basis: calc(33.33% - 20px);
    margin: 20px 0 0 20px;
  }

  @media (min-width: $tablet + 1) and (max-width: $web-medium - 100) {
    flex-basis: calc(50% - 10px);
    margin: 10px 0 0 10px;
  }

  @media (min-width: $web-medium - 101) and (max-width: $web-medium-max) {
    flex-basis: calc(28%);
    margin: 30px 0 0 30px;
  }

  @media (min-width: $web-medium-max + 1) and (max-width: $web-medium-max + 300) {
    margin: 24px 0 0 24px;
  }
}

body:lang(en) {
  .item {
    @media (max-width: $mobile + 220) {
      margin: 6px 6px 0 0;
    }

    @media (min-width: $mobile + 221) and (max-width: $tablet) {
      margin: 20px 20px 0 0;
    }

    @media (min-width: $tablet + 1) and (max-width: $web-medium - 100) {
      margin: 10px 10px 0 0;
    }

    @media (min-width: $web-medium - 101) and (max-width: $web-medium-max) {
      margin: 30px 30px 0 0;
    }

    @media (min-width: $web-medium-max + 1) and (max-width: $web-medium-max + 300) {
      margin: 24px 24px 0 0;
    }
  }
}


.droppableRow {
  // width: 100%;
  background: #e6f6e2e0;
}
.isDropping {
  background: #cbf1c1;
  // width: 100%;
}
.draggingRow {
  // cursor: move !important;
  background: #d5e7fca6;
}