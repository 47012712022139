@import '../../../styles/global.scss';

.container {
  @include flex(row, wrap);
  // margin-right: 52px;

  @media (max-width: $web-medium-max) {
    margin-right: 0;
  }

  @media (max-width: $tablet - 277) {
    justify-content: center;
  }

  @media (max-width: $mobile - 1) {
    margin-right: 0;
  }
}

.left {
  position: absolute;
  justify-content: space-evenly;
  display: flex;
  top: 33px;
  left: 3%;
  width: 80px;
}

@media (max-width: 771px) {
  .left {
    top: 13px !important;
  }
}

body:lang(en) {
  .container {
    margin-right: 0;
    @media (max-width: $web-medium-max) {
      margin-left: 0;
    }
  }
}
